//保存token
export function setUserInfo(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

export function getToken() {
	let userInfo = getUserInfo();
	let time = parseInt(new Date().getTime() / 1000) + '';
	console.log(time) 
	if(userInfo.valid_time!=undefined&&userInfo.valid_time>time)
	{		
		return JSON.parse(localStorage.getItem('userInfo')).token
	}
	removeUserInfo();
	return "";
    
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'))
}

export function getAuthRoles() {
	
	if(localStorage.getItem('userInfo')==null)return "";
	if(localStorage.getItem('userInfo')=="") return "";
	let userInfo =JSON.parse(localStorage.getItem('userInfo'));
	console.log(userInfo);
	if(userInfo.roles){
		return JSON.parse(localStorage.getItem('userInfo')).roles.auth_roles;
	}	
	return "";
    
}

//删除本地token
export function removeUserInfo() {
	let userInfo = getUserInfo();
	if(userInfo!=undefined)
	{
		localStorage.removeItem('userInfo');
	}
}
