import Vue from "vue"
import Router from 'vue-router'

Vue.use(Router)

export const staticRoutes = [

    {
        path: '/login',
        component: () => import('@/views/login/login.vue'),
        hidden: true,
        meta: {
			 title: '用户登录',
            requireAuth: false
        }
    },
    {
        path: '/',
        component: () => import('@/views/dashboard/dashboard.vue'),
        hidden: true,
        name: '首页',
        meta: {
            title: '首页',
            requireAuth: true
        }

    },
    {
        path: '/purchase',
        component: () => import('@/views/purchase/purchase.vue'),
        meta: {
            title: '采购',
            requireAuth: true
        },

    },

    {
        path: '/instorage',

        component: () => import('@/views/purchase/instorage/instorage.vue'),
        // name: 'Instorage',
        meta: {
            title: '采购入库',
            up: {
                path: "/purchase",
                title: "采购"
            },

            requireAuth: true,
        }
    },
    {
        path: '/purchasewait',
        component: () => import('@/views/purchase/wait/wait.vue'),
        meta: {
            title: '待审核',
            up: {
                path: "/purchase",
                title: "采购"
            },
            requireAuth: true
        }
    },
    {
        path: '/purchaseorder',
        component: () => import('@/views/purchase/order/index.vue'),
        meta: {
            title: '已入库',
            up: {
                path: "/purchase",
                title: "采购"
            },
            requireAuth: true
        }
    },
    {
        path: '/setting',
        component: () => import('@/views/setting/setting.vue'),
        meta: {
            title: '设置',
            requireAuth: true
        },

    },
    {
        path: '/product',
        component: () => import('@/views/setting/product/product.vue'),
        meta: {
            title: '产品管理',
            up: {
                path: "/setting",
                title: "设置"
            },
            requireAuth: true,
        }
    },
    {
        path: '/classify',
        component: () => import('@/views/setting/product/classify/classify.vue'),
        meta: {
            title: '分类管理',
            ups: [
            	{
            	    path: "/setting",
            	    title: "设置"
            	},
            	{
            	    path: "/product",
            	    title: "产品管理"
            	}
            ],
            requireAuth: true,
        }
    },
    {
        path: '/manage',
        component: () => import('@/views/setting/product/manage/manage.vue'),
        meta: {
            title: '产品管理',
            ups: [
				{
				    path: "/setting",
				    title: "设置"
				},
				{
				    path: "/product",
				    title: "产品管理"
				}
			],
            requireAuth: true,
        }
    },
	{
	    path: '/inventory',
	    component: () => import('@/views/setting/product/inventory/index.vue'),
	    meta: {
	        title: '盘库记录',
	        ups: [
				{
				    path: "/setting",
				    title: "设置"
				},
				{
				    path: "/product",
				    title: "产品管理"
				}
			],
	        requireAuth: true,
	    }
	},
    {
        path: '/attribute',
        component: () => import('@/views/setting/product/attribute/attribute.vue'),
        meta: {
            title: '属性管理',
            ups: [
            	{
            	    path: "/setting",
            	    title: "设置"
            	},
            	{
            	    path: "/product",
            	    title: "产品管理"
            	}
            ],
            requireAuth: true,
        }
    },
    {
        path: '/offerings',
        component: () => import('@/views/setting/offerings/offerings.vue'),
        meta: {
            title: '供/销账户',
            up: {
                path: "/setting",
                title: "设置"
            },
            requireAuth: true,
        }
    },
    {
        path: '/setup',
        component: () => import('@/views/setting/setup/setup.vue'),
        meta: {
            title: '仓库/账户',
            up: {
                path: "/setting",
                title: "设置"
            },
            requireAuth: true,
        }
    },
    {
        path: '/limits',
        component: () => import('@/views/setting/limits/limits.vue'),
        meta: {
            title: '权限设置',
            up: {
                path: "/setting",
                title: "设置"
            },
            requireAuth: true,
        }
    },
    {
	    path: '/sale',
	    component: () => import('@/views/sale/index.vue'),
	    meta: {title: '销售',
		 requireAuth: true
		},

	},
	{
	    path: '/presale',
	    component: () => import('@/views/presale/index.vue'),
	    meta: {title: '预定',
		 requireAuth: false
		},
	
	},
	{
	    path: '/prewait',
	    component: () => import('@/views/presale/prewait.vue'),
	    meta: {title: '提交审核',
			up:{
				path:"/presale",
				title:"预定"
			},
		 requireAuth: false}
	},
	{
	    path: '/predoing',
	    component: () => import('@/views/presale/predoing.vue'),
	    meta: {title: '生产中',
			up:{
				path:"/presale",
				title:"预定"
			},
		 requireAuth: false}
	},
	{
	    path: '/prefinish',
	    component: () => import('@/views/presale/prefinish.vue'),
	    meta: {title: '生产中',
			up:{
				path:"/presale",
				title:"预定"
			},
		 requireAuth: false}
	},
	{
	    path: '/outstock',

	    component: () => import('@/views/sale/outstock/outstock.vue'),
	    meta: {
			title: '销售出库',
			up:{
				path:"/sale",
				title:"销售"
			},
			requireAuth: true,
		 }
	},
	{
	    path: '/salewait',
	    component: () => import('@/views/sale/wait/wait.vue'),
	    meta: {title: '待审核',
			up:{
				path:"/sale",
				title:"销售"
			},
		 requireAuth: true}
	},
	{
	    path: '/saleorder',
	    component: () => import('@/views/sale/order/index.vue'),
	    meta: {title: '已出库',
			up:{
				path:"/sale",
				title:"销售"
			},
		 requireAuth: true},
	},
   {
	   path: '/finance',
        component: () => import('@/views/finance/finance.vue'),
        meta: {
            title: '财务',
            requireAuth: true
        },

    },
    {
        path: '/pay',
        component: () => import('@/views/finance/pay/pay.vue'),
        meta: {
            title: '财务支出',
            up: {
                path: "/finance",
                title: "财务"
            },
            requireAuth: true,
        }
    },
    {
        path: '/unsettled',
        component: () => import('@/views/finance/pay/unsettled/unsettled.vue'),
        meta: {
            title: '支出未结算',
            ups: [
				{
				    path: "/finance",
				    title: "财务"
				},
				{
				    path: "/pay",
				    title: "财务支出"
				}
			],
            requireAuth: true,
        }
    },
    {
        path: '/settled',
        component: () => import('@/views/finance/pay/settled/settled.vue'),
        meta: {
            title: '支出已结算',
            ups: [
            	{
            	    path: "/finance",
            	    title: "财务"
            	},
            	{
            	    path: "/pay",
            	    title: "财务支出"
            	}
            ],
            requireAuth: true,
        }
    },
    {
        path: '/income',
        component: () => import('@/views/finance/income/income.vue'),
        meta: {
            title: '财务收入',
            up: {
                path: "/finance",
                title: "财务"
            },
			
            requireAuth: true,
        }
    },
    {
        path: '/income_settled',
        component: () => import('@/views/finance/income/income_settled/income_settled.vue'),
        meta: {
            title: '收入已结算', 
			ups: [
				{
				    path: "/finance",
				    title: "财务"
				},
				{
				    path: "/income",
				    title: "财务收入"
				}
			],
            requireAuth: true,
        }
    },
    {
        path: '/income_unsettled',
        component: () => import('@/views/finance/income//income_unsettled/income_unsettled.vue'),
        meta: {
            title: '收入未结算',
           ups: [
           	{
           	    path: "/finance",
           	    title: "财务"
           	},
           	{
           	    path: "/income",
           	    title: "财务收入"
           	}
           ],
            requireAuth: true,
        }
    },
    {
        path: '/account',
        component: () => import('@/views/finance/account/account.vue'),
        meta: {
            title: '公司账户',
            up: {
                path: "/finance",
                title: "财务"
            },
            requireAuth: true,
        }
    },
    {
        path: '/storehouse',
        component: () => import('@/views/storehouse/list.vue'),
        meta: {
            title: '仓库',
            requireAuth: true
        },

    },


]
const createRouter = () => new Router({
    routes: staticRoutes,
    mode: "history"
})

const router = createRouter()
export default router

